// 
// Table Common
// 

.table thead th,
.table th {
  font-weight: 500;
}

.no-wrap {
  td,
  th {
      white-space: nowrap;
  }
}

// 
// Data-tables
// 
table.dataTable {
  thead {
    th,
    td {
      border-bottom-color: $border-color;
      text-align: center;
    }
  }
  tbody {
    td {
      text-align: center;
    }
  }
}


.ngx-datatable.material{
  border: 1px solid $border-color;
  box-shadow: none !important;
}