.alert-danger,
.alert-info,
.alert-primary,
.alert-success,
.alert-warning {
  color: $white;
}

.alert.customize-alert {
  padding: 20px 25px;
  .side-line {
    position: absolute;
    width: 3px;
    height: 25px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/12px auto no-repeat;
}

// toast ng bootstrap component
.ngb-toasts {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0.5em;
  z-index: 1200;
}

.text-bg-success,
.text-bg-danger,
.text-bg-warning {
  color: $white !important;
}