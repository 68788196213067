// Custom select
.form-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.form-check-input {
  z-index: 1;
}

label {
  font-weight: 400;
}

//
// Row Separator page
//
.r-separator .form-group {
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: none;
  }
}

//
// Striped Row page
//
.striped-rows .row:nth-child(odd) {
  background-color: $gray-100;
}

//
// Border form page
//
.b-form .row {
  border-bottom: 1px solid $border-color;
  margin-bottom: 0;
  padding: 15px 0;

  &:last-child {
    border-bottom: none;
  }
}

//
// Custom validation Classes
//
.error {
  .form-control {
    border-color: $danger;
  }

  .help-block {
    color: $danger;
  }
}

.validate {
  .form-control {
    border-color: $success;
  }

  .help-block {
    color: $success;
  }
}
