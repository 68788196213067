// scafholding
* {
  outline: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  color: $body-color;
  background: $main-body-bg;
  position: relative;
}

html {
  position: relative;
  min-height: 100%;
}

a {
  color: $themecolor;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

a.link {
  color: $body-color;
  &:hover,
  &:focus {
    color: $themecolor;
  }
}

.box {
  border-radius: $border-radius;
  padding: 10px;
}

/*******************
Blockquote
*******************/

html body blockquote {
  border-left: 5px solid $primary;
  border: 1px solid $border-color;
  padding: 15px;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

.hide {
  display: none;
}

//
// Opacity
//

.op-5 {
  opacity: 0.5;
}

.op-3 {
  opacity: 0.3;
}

// 
// Font weight
// 

html body {
  .font-medium {
    font-weight: $font-weight-medium;
  }
  .font-22 {
    font-size: 22px;
  }
}

//
// Background Colors
//
html body {
  .bg-megna {
    background-color: $cyan;
  }
  .bg-inverse {
    background-color: $gray-900;
  }
  .bg-purple {
    background-color: $purple;
  }
}

// 
// Round
// 
.round {
  line-height: 48px;
  width: 50px;
  height: 50px;
  background: $blue;
  line-height: 52px;
  img {
    border-radius: 100%;
  }
  &.round-info {
    background: $blue;
  }

  &.round-warning {
    background: $warning;
  }

  &.round-danger {
    background: $danger;
  }

  &.round-success {
    background: $success;
  }

  &.round-primary {
    background: $primary;
  }
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}

//
// Badge
//

.badge {
  &.badge-pill {
    padding: 0.2em 0.6em;
  }
}

//
// Lists
//

ul.list-style-none {
  margin: 0px;
  padding: 0px;
  li {
    list-style: none;
    a {
      color: $body-color;
      padding: 8px 0px;
      display: block;
      text-decoration: none;
      &:hover {
        color: $themecolor;
      }
    }
  }
}

// Dashboard Table

.popover-icon {
  margin-left: -15px;

  &:hover {
    z-index: 10;
    position: relative;
  }
}