.feed-widget {
  .feed-body {
      .feed-item {
          padding: 12px 0;
          display: flex;
          align-items: center;
          &:hover {
              background: $gray-100;
          }
          >.feed-icon {
              width: 40px;
              height: 40px;
              i {
                  line-height: 40px;
              }
          }
      }
  }
}
