// this is bootstrap override

[dir="rtl"] {
  @import "spacing-rtl";
  @import "rtl-components";
  // horizontal rtl
  @import "horizontal-rtl";

  text-align: right;
  direction: rtl;

  .page-breadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item {
    padding-right: 0.5rem;
    padding-left: 0;
    &::before {
      content: "\e64a";
      padding-right: 0;
      padding-left: 0.5rem;
    }
  }

  .sidebar-nav ul li {
    text-align: right;
    direction: rtl;
  }

  .hide-menu {
    margin-right: 0 !important;
    margin-left: 0;
  }

  .steamline {
    border-right: 1px solid rgba(120, 130, 140, 0.13);
    border-left: 0;
    margin-right: 20px;
    margin-left: 0;
    .sl-left {
      margin-right: -20px;
      margin-left: 15px;
      float: right;
    }
    .sl-right {
      padding-right: 30px;
      padding-left: 0;
    }
  }

  .steamline .sl-left {
    margin-left: 6px !important;
    margin-right: -15px !important;
  }

  .lstick {
    margin-right: -20px;
    margin-left: 18px;
  }

  .form-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    //background: url(../../../assets/images/custom-select.png) left 0.35rem center no-repeat $white;
  }

  //
  // recent comments
  //
  .comment-footer .action-icons a {
    padding-left: 0;
    padding-right: 7px;
  }

  #btnAddContact {
    position: absolute !important;
    left: 15px;
    right: unset !important;
    top: 15px !important;
  }

  .note-color {
    left: unset !important;
    right: 0;
  }

  .note-title,
  .note-time {
    margin-right: 15px;
    margin-left: 0 !important;
  }

  .custom-control {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .form-check-label::after,
  .form-check-label::before {
    left: auto;
    right: -25px;
  }

  .list-group {
    padding: 0px;
  }


  .comment-footer .action-icons a {
    padding-right: 7px;
    padding-left: 0;
  }

  .modal-header .close {
    float: left;
    margin: -1rem auto -1rem -1rem;
  }

  .modal-footer > :not(:last-child) {
    margin-left: 0.25rem;
    margin-right: 0;
  }

  .alert-dismissible {
    padding-left: 3.8125rem;
    padding-right: 1.25rem;
    .close {
      left: 0;
      right: auto;
    }
  }

  .custom-file-label {
    left: auto;
    right: 0;
    width: 100%;
    &::after {
      right: auto;
      left: 0;
    }
  }

  .dropdown-menu {
    text-align: right;
    right: 0 !important;

    &.dropdown-menu-right {
      right: auto !important;
      left: 0 !important;
      transform: translate(0px, 60px) !important;
    }
  }

  @media (max-width: 767px) {
    .dropdown-menu {
      &.dropdown-menu-right {
        //top: $topbar-height !important;
      }
    }
  }

  .topbar .navbar-collapse {
    padding: 0 0 0 10px;
  }

  .navbar-nav,
  .nav,
  .list-unstyled,
  .pagination {
    padding-right: 0;
  }

  .topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
    margin-right: 0;
  }

  .topbar .dropdown-menu.dropdown-menu-right .with-arrow {
    left: 0;
    right: auto;
  }

  .topbar .dropdown-menu.dropdown-menu-right .with-arrow > span {
    left: 20px;
    right: auto;
  }

  .search-box .app-search .srh-btn {
    right: auto;
    left: 20px;
  }

  .mailbox .message-center .message-item .mail-contnet {
    padding-right: 10px;
    padding-left: 0;
  }

  .sidebar-nav ul .sidebar-item .sidebar-link .side-badge.badge {
    left: 32px;
    right: unset;
  }

  .user-profile {
    padding: 10px 10px 10px 0;
  }

  .user-profile span img {
    margin-right: 0;
    margin-left: 10px;
  }

  .customizer {
    left: -280px;
    right: auto;
    &.show-service-panel {
      left: 0;
      right: auto;
      .service-panel-toggle {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 100%;
        border-top-right-radius: 100%;
        right: -55px;
      }
    }
    .service-panel-toggle {
      right: -70px;
      left: auto;
    }
  }

  .sidebar-nav .has-arrow::after {
    margin-left: 0;
    margin-right: 10px;
    right: auto;
    left: 15px;
  }

  /* Stickey inner-left-part */

  $left-part-width: 260px;

  .left-part {
    border-left: 1px solid $border-color;
    border-right: none;
    .show-left-part {
      left: -41px;
      right: auto;
    }
  }

  .right-part {
    margin-right: 0;
    margin-left: 0;
    .dropdown-menu {
      right: auto !important;
      left: 0 !important;
    }
  }

  .reverse-mode {
    .left-part {
      right: auto;
      left: 0;
    }
    .show-left-part {
      left: auto;
      right: -41px;
    }
    .right-part {
      margin-right: 0px;
      margin-left: $left-part-width;
    }
  }

  .profiletimeline {
    padding-right: 40px;
    padding-left: 0;
    margin: 40px 30px 0 10px;
    border-right: 1px solid $border-color;
    border-left: none;
    .sl-left {
      float: right;
      margin-right: -60px;
      margin-left: 15px;
    }
  }

  .feed-widget .feed-body .feed-item > .feed-icon {
    margin-right: 0;
    margin-left: 10px;
  }

  .chat-list {
    .chat-item {
      .chat-content {
        padding-right: 15px;
        padding-left: 0;
      }
      .chat-time {
        margin: 5px 65px 15px 0;
      }
      &.odd .chat-content,
      &.odd .chat-time {
        text-align: left;
      }
    }
  }

  /* plugins */

  

  .datepicker {
    direction: rtl;
  }
   

  .wizard > .steps > ul > li,
  .wizard > .actions > ul > li {
    float: right;
  }

  .wizard-content .wizard > .steps > ul > li:after {
    right: auto;
    left: 0;
  }

  .wizard-content .wizard > .steps > ul > li:before {
    left: auto;
    right: 0;
  }

  .wizard-content .wizard.vertical > .steps {
    float: right;
  }

  &#main-wrapper {
    /*sidebar type*/

    &[data-sidebartype="full"] {
      .page-wrapper {
        margin-right: $sidebar-width-full;
        margin-left: 0;
      }
    }

    &[data-sidebartype="iconbar"] {
      .page-wrapper {
        margin-right: $sidebar-width-iconbar;
        margin-left: 0;
      }
    }

    &[data-sidebartype="overlay"] {
      .left-sidebar {
        right: -$sidebar-width-full;
        left: auto;
      }
      &.show-sidebar {
        .left-sidebar {
          right: 0;
          left: auto;
        }
      }
    }
  }

  .dz-hidden-input {
    display: none;
  }

  // apexchart tooltip rtl

  .apexcharts-tooltip-series-group {
    text-align: right;
  }

  .apexcharts-tooltip-marker {
    margin-right: 0;
    margin-left: 10px;
  }

  .apexcharts-tooltip-text-value,
  .apexcharts-tooltip-text-z-value {
    margin-left: 0;
    margin-right: 5px;
  }

  /* Vertical responsive layout */

  @include media-breakpoint-up(lg) {
    &#main-wrapper {
      /*Sidebar position*/

      &[data-sidebar-position="fixed"][data-sidebartype="full"],
      &[data-sidebar-position="fixed"][data-sidebartype="overlay"] {
        .topbar .top-navbar .navbar-collapse {
          margin-right: $sidebar-width-full;
          margin-left: 0;
        }
      }

      &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
        .topbar .top-navbar .navbar-collapse {
          margin-right: $sidebar-width-mini;
          margin-left: 0;
        }
      }

      &[data-sidebar-position="fixed"][data-sidebartype="iconbar"] {
        .topbar .top-navbar .navbar-collapse {
          margin-right: $sidebar-width-iconbar;
          margin-left: 0;
        }
      }

      /*Fixed sidebar with minisidebar*/

      &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
        .topbar .top-navbar .navbar-collapse {
          margin-right: $sidebar-width-mini;
          margin-left: 0;
        }
      }

      &[data-sidebartype="mini-sidebar"] {
        .page-wrapper {
          margin-right: $sidebar-width-mini;
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 766px) {
    &#main-wrapper {
      &[data-sidebartype="mini-sidebar"] {
        .left-sidebar {
          right: -$sidebar-width-full;
          left: auto;
        }
      }

      &.show-sidebar {
        .left-sidebar {
          right: 0;
          left: auto;
        }
      }
    }

    .left-part {
      right: -260px;
      left: auto;
      &.show-panel {
        right: 0px;
        left: auto;
      }
    }

    .right-part {
      margin-right: 0px;
    }
  }

  // new

  @media (max-width: 767px) {
    .topbar .top-navbar {
      .dropdown-menu {
        left: auto !important;
        right: 0 !important;
        transform: translate(0px, 64px) !important;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &#main-wrapper {
      &[data-sidebartype="full"],
      &[data-sidebartype="iconbar"],
      &[data-sidebartype="mini-sidebar"],
      &[data-sidebartype="overlay"] {
        .left-sidebar {
          left: unset;
          right: -250px;
        }
      }
      &.show-sidebar {
        .left-sidebar {
          right: 0;
          left: unset !important;
        }
      }
      .page-wrapper {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }
  }
}
