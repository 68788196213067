//  card title
.lstick {
  width: 2px;
  background: $info;
  height: 30px;
  margin-left: -20px;
  margin-right: 18px;
  display: inline-block;
  vertical-align: middle;
}

// Icons Page
.icon-hover {
  cursor: pointer;
  i {
    font-size: 20px;
    margin-right: 8px;
  }
  &:hover {
    background-color: $gray-100;
    color: $info;
  }
}

// this is for custom pills
.custom-pills {
  .nav-pills {
    .nav-link {
      border-radius: 0;
      opacity: 0.7;

      &.active {
        color: $themecolor;
        opacity: 1;
        background-color: transparent;
        border-bottom: 2px solid $themecolor;
      }
    }
  }
}

// scrollbar
.scrollable {
  position: relative;
}

// 
// Pricing Page
// 
.price-label {
  position: absolute;
  top: -10px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100px;
  padding: 5px 10px;
}

.price-sign {
  position: absolute;
  font-size: 15px;
  top: 5px;
  margin-left: -10px;
}

.cal-month-view .cal-events {
  margin: 0;
  padding: 3px;
}

.weather-img {
  min-height: 155px;
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

// 
// Feather Icon
// 
.feather-sm {
  width: 18px !important;
  height: 18px !important;
}

.feather-lg {
  width: 28px !important;
  height: 28px !important;
}

.fill-white .feather {
  fill: rgba(255, 255, 255, 0.1);
}

// calendar
.flatpickr-day {
  color: $body-color !important;
}

// image overlay
.image-overlay {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
  }
}