//
// Font Colors
//
.text-dark {
  color: $gray-200 !important;
}

//
// Button and Background colors
//

.bg-white {
  background-color: $theme-dark-card-bg !important;
}

.bg-dark {
  background-color: $gray-600 !important;
}

.round.round-danger {
  background: rgba($color: $danger, $alpha: 0.5);
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: rgba($color: $danger, $alpha: 0.2) !important;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: rgba($color: $success, $alpha: 0.2) !important;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: rgba($color: $primary, $alpha: 0.2) !important;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: rgba($color: $info, $alpha: 0.2) !important;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: rgba($color: $warning, $alpha: 0.2) !important;
}

.bg-light {
  background-color: $theme-dark-bg-light !important;
}

.btn-light {
  background-color: $gray-800;
  border-color: $gray-800;
  color: $theme-dark-text-color;
}

//   calendar app
.rbc-toolbar button {
  background-color: rgba($color: $info, $alpha: 0.5);
  border-color: rgba($color: $info, $alpha: 0.5);
  color: $white;
  &:hover {
    background-color: rgba($color: $info, $alpha: 0.7);
    border-color: rgba($color: $info, $alpha: 0.5);
    color: $white;
  }
}

.rbc-toolbar button.active,
.rbc-toolbar button.rbc-active {
  background-color: rgba($color: $info, $alpha: 0.8);
  border-color: rgba($color: $info, $alpha: 0.8);
}

.rbc-event.event-default {
  background-color: rgba($color: $info, $alpha: 0.5);
}

.rbc-event.event-azure {
  background-color: rgba($color: $cyan, $alpha: 0.5);
}
.rbc-event.event-orange {
  background-color: rgba($color: $warning, $alpha: 0.5);
}
.rbc-event.event-green {
  background-color: rgba($color: $success, $alpha: 0.5);
}
.rbc-event.event-red {
  background-color: rgba($color: $danger, $alpha: 0.5);
}
